import { AxiosResponse } from 'axios'
import axios from '../instance'
import { OrderFillInPayload } from './fill.type'

export const getReferenceDetail = async (reference: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/general/order', {
			params: {
				reference
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const submitFillInOrder = async (reference: string, payload: OrderFillInPayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.post(`/general/order/${reference}`, payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

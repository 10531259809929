import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08c742dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-list" }
const _hoisted_2 = { class: "product-list__image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "product-list__detail" }
const _hoisted_5 = { class: "product-list__detail--product-name" }
const _hoisted_6 = {
  key: 0,
  style: {"color":"#197b00"}
}
const _hoisted_7 = { class: "price-wrapper--qty" }
const _hoisted_8 = { class: "product-list__detail--sku" }
const _hoisted_9 = { class: "price-wrapper" }
const _hoisted_10 = { class: "price-wrapper--price" }
const _hoisted_11 = {
  class: "btn-product-list",
  size: "small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_image, {
        class: "product-list__image--image",
        fit: "contain",
        src: _ctx.imageUrl
      }, {
        error: _withCtx(() => [
          _createElementVNode("img", {
            class: "product-list__image--image",
            src: 
							require('@/assets/images/other/not-found-image.png')
						,
            alt: ""
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, [
          (_ctx.productSet?.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "[เซ็ต]"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
        ]),
        _createElementVNode("div", _hoisted_7, "x" + _toDisplayString(_ctx.qty), 1)
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.sku), 1),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, " ฿" + _toDisplayString(_ctx.price.toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})), 1)
      ]),
      _createElementVNode("div", null, [
        (_ctx.productSet?.length > 0)
          ? (_openBlock(), _createBlock(_component_el_popover, {
              key: 0,
              placement: "bottom",
              width: 400,
              trigger: "click"
            }, {
              reference: _withCtx(() => [
                _createElementVNode("button", _hoisted_11, "ชุดสินค้าทั้งหมด (x" + _toDisplayString(_ctx.productSet?.length) + ")", 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_table, { data: _ctx.productSet }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      property: "product.name",
                      label: "name"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.getProductNameWithTypes(scope.row.product)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      property: "product.sku",
                      label: "sku"
                    }),
                    _createVNode(_component_el_table_column, {
                      property: "product.price",
                      label: "price"
                    }),
                    _createVNode(_component_el_table_column, {
                      property: "unit",
                      label: "จำนวน"
                    })
                  ]),
                  _: 1
                }, 8, ["data"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
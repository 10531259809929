
import { getProductNameWithTypes } from '@/utils/productName'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'ProductDetailFillIn',
	props: {
		imageUrl: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		sku: {
			type: String,
			default: '',
		},
		price: {
			type: [String, Number] as PropType<string | number>,
			default: 0,
		},
		qty: {
			type: Number as PropType<number>,
			default: 0,
		},
		productSet: {
			type: Array,
			default: () => [],
		},
	},

	setup() {
		return { getProductNameWithTypes }
	},
})
